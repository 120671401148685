<template>
  <div>
    <b-modal
      :no-close-on-backdrop="true"
      ref="verfication-modal"
      hide-footer
      title="Confirmation"
    >
      <div class="d-block">
        <h4>{{ this.modalBodyText }}</h4>
      </div>
      <div style="float: right">
        <button
          ref="kt_login_signin_submit"
          type="button"
          class="btn btn-danger mr-3"
          @click="commonAction()"
        >
          Yes
        </button>
        <button type="button" class="btn btn-secondary" @click="hideModal">
          No
        </button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import axios from "axios";
import ApiService from "@/core/services/api.service";
export default {
  props: {
    userObj: {
      type: Object,
    },
    actionType: {
      type: String,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showModel: this.show,
      modalBodyText: "",
    };
  },

  mounted() {
    if (this.actionType == "delete-resturant-image") {
      this.modalBodyText = "Do You Want To Delete This Record?";
    }

    if (this.actionType == "delete-menu-item") {
      this.modalBodyText = "Do You Want To Delete This Record?";
    }

    if (this.actionType == "delete") {
      this.modalBodyText = "Do You Want To Delete This Record?";
    }

    if (this.actionType == "deactive") {
      this.modalBodyText = "Do You Want To Activate This Record?";
    }
    if (this.actionType == "delete-skip-image") {
      this.modalBodyText = "Do You Want To Delete This Image?";
    }
    if (this.actionType == "active") {
      this.modalBodyText = "Do You Want To Deactivate This Record?";
    }

    this.$refs["verfication-modal"].show();
    this.showModel = true;
  },

  methods: {
    hideModal() {
      this.showModel = false;
      this.$refs["verfication-modal"].hide();
      this.$emit("closeModal", false);
    },

    deleteRecord() {
      try {
        const data = { id: this.userObj.item.id };
        const headers = {
          Authorization: "Bearer " + localStorage.getItem("id_token"),
        };
        axios
          .post(this.$path + "/user/discount/softDelete", data, {
            headers,
          })
          .then((response) => {
            if (response.data.code == 200) {
              this.$refs["verfication-modal"].hide();
              this.$emit("closeModal", false);
              this.$emit("deleteRow", this.userObj);
            }
          });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },

    deleteRestaurantImage() {
      try {
        const data = { image_id: this.userObj.item.id };
        // const headers = {
        //   Authorization: "Bearer " + localStorage.getItem("token"),
        // };
        ApiService.post(this.$path + "/delete-restaurant-image", data, {
          // headers,
        }).then((response) => {
          if (response.data.code == 200) {
            this.$refs["verfication-modal"].hide();
            this.$emit("closeModal", false);
            this.$emit("deleteImageRecord", this.userObj);
          }
        });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },

    deleteMeunItem() {
      this.$emit("closeModal", false);
      this.$emit("deleteMeunItem", this.userObj);
    },

    changeStatus() {
      try {
        const data = {
          id: this.userObj.item.id,
          status: this.userObj.item.status,
        };
        const headers = {
          Authorization: "Bearer " + localStorage.getItem("id_token"),
        };
        axios
          .post(this.$path + "/user/paymentgateway/activeDeactive", data, {
            headers,
          })
          .then((response) => {
            if (response.data.code == 200) {
              this.postButtonLoader = true;
              this.$refs["verfication-modal"].hide();
              this.$emit("closeModal", false);
              this.$emit("changeStatus", response.data.data);
            }
          });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },

    commonAction() {
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      if (this.actionType == "delete") {
        this.deleteRecord();
      }
      if (this.actionType == "delete-resturant-image") {
        this.deleteRestaurantImage();
      }
      if (this.actionType == "delete-menu-item") {
        this.deleteMeunItem();
      }
      if (this.actionType == "active" || this.actionType == "deactive") {
        this.changeStatus();
      }
    },
  },
};
</script>
